import { selectObjects } from 'features/objects/objects-slice';
import { IComplex } from 'features/objects/types';
import {
  closeComplexDrawer,
  closeComplexModal,
  selectUi,
} from 'features/ui/ui-slice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { calculateFlatsStatuses, findFlatById } from 'utils/objects';
import { ComplexDrawer } from '../complex-drawer';
import { ComplexHouse } from '../complex-house';
import { ComplexHouseSelector } from '../complex-house-selector';
import { ComplexModal } from '../complex-modal';
import { Statuses } from 'components/statuses';
import styles from './complex.module.scss';

interface Props {
  complex: IComplex;
}

export const Complex: FC<Props> = ({ complex }) => {
  const dispatch = useAppDispatch();
  const objects = useSelector(selectObjects);
  const ui = useSelector(selectUi);

  const currentHouse = complex.houses.find((item) => item.isActive);

  if (!currentHouse) {
    return null;
  }

  const flatsStatuses = calculateFlatsStatuses(complex);

  const currentFlat = findFlatById(objects.items, ui.complexDrawer.flatId);

  return (
    <div className={styles.module}>
      <ComplexHouseSelector houses={complex.houses} />

      <Statuses type="complex" data={flatsStatuses} />

      <ComplexHouse house={currentHouse} />

      {currentFlat && (
        <ComplexDrawer
          isOpen={ui.complexDrawer.isOpen}
          onClose={() => dispatch(closeComplexDrawer())}
          currentFlat={currentFlat}
        />
      )}
      {currentFlat && ui.complexModalIsOpen && (
        <ComplexModal
          isOpen={ui.complexModalIsOpen}
          onClose={() => dispatch(closeComplexModal())}
          currentFlat={currentFlat}
        />
      )}
    </div>
  );
};
