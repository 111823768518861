import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { UiSlice } from './types';

const initialState: UiSlice = {
  loginModalIsOpen: false,
  complexModalIsOpen: false,
  complexDrawer: {
    isOpen: false,
    flatId: null,
  },
  villageModalIsOpen: false,
  villageDrawer: {
    isOpen: false,
    entityId: null,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openLoginModal: (state) => {
      state.loginModalIsOpen = true;
    },
    closeLoginModal: (state) => {
      state.loginModalIsOpen = false;
    },
    openComplexDrawer: (state, { payload }: PayloadAction<string>) => {
      state.complexDrawer.isOpen = true;
      state.complexDrawer.flatId = payload;
    },
    closeComplexDrawer: (state) => {
      state.complexDrawer.isOpen = false;
      state.complexDrawer.flatId = null;
    },
    openComplexModal: (state) => {
      state.complexModalIsOpen = true;
    },
    closeComplexModal: (state) => {
      state.complexModalIsOpen = false;
    },
    openVillageDrawer: (state, { payload }: PayloadAction<string>) => {
      state.villageDrawer.isOpen = true;
      state.villageDrawer.entityId = payload;
    },
    closeVillageDrawer: (state) => {
      state.villageDrawer.isOpen = false;
      state.villageDrawer.entityId = null;
    },
    openVillageModal: (state) => {
      state.villageModalIsOpen = true;
    },
    closeVillageModal: (state) => {
      state.villageModalIsOpen = false;
    },
  },
});

export const {
  openLoginModal,
  closeLoginModal,
  openComplexDrawer,
  closeComplexDrawer,
  openComplexModal,
  closeComplexModal,
  openVillageDrawer,
  closeVillageDrawer,
  openVillageModal,
  closeVillageModal,
} = uiSlice.actions;

export const selectUi = (state: RootState) => state.ui;

export const uiReducer = uiSlice.reducer;
