import { FC } from 'react';
import cn from 'classnames';
import { IEntity, IQueue, IVillage } from 'features/objects/types';
import styles from './village-queue.module.scss';
import { VillageQuarter } from 'features/objects/components/village-quarter';
import { useSelector } from 'react-redux';
import { selectUi } from 'features/ui/ui-slice';

interface Props {
  queue: IQueue;
}

export const VillageQueue: FC<Props> = ({ queue }) => {
  const ui = useSelector(selectUi);

  const moduleClasses = cn(styles.module, {
    [styles.shrink]: ui.villageDrawer.isOpen,
  });

  return (
    <div className={moduleClasses}>
      <p className={styles.title}>{queue.name}</p>

      <div className={styles.quarters}>
        {queue.quarters.map((item) => (
          <VillageQuarter key={item.id} quarter={item} />
        ))}
      </div>
    </div>
  );
};
