import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { loginUser } from 'features/user/user-slice';
import styles from './login-modal.module.scss';
import { Modal } from 'components/modal';
import { Input } from 'components/input';
import { Button } from 'components/button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(loginUser(login, password));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.module}>
        <button className={styles.close} onClick={onClose}>
          ✕
        </button>
        <div className={styles.content}>
          <p className={styles.title}>Вход</p>
          <form onSubmit={handleSubmit}>
            <Input
              moduleStyles={styles.login}
              label="Логин"
              value={login}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setLogin(event.target.value)
              }
            />
            <Input
              moduleStyles={styles.password}
              label="Пароль"
              type="password"
              value={password}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
            />
            <Button label="Войти" moduleStyles={styles.submit} />
          </form>
        </div>
      </div>
    </Modal>
  );
};
