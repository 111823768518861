import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { selectUi } from 'features/ui/ui-slice';
import { IHouse } from 'features/objects/types';
import styles from './complex-house.module.scss';
import { ComplexEntrance } from '../complex-entrance';
import { ComplexFloorColumn } from '../complex-floor-column';

interface Props {
  house: IHouse;
}

export const ComplexHouse: FC<Props> = ({ house }) => {
  const ui = useSelector(selectUi);

  let maxFloor = 0;
  house.entrances.forEach((entrance) => {
    if (maxFloor < entrance.floors.length) {
      maxFloor = entrance.floors.length;
    }
  });

  const moduleClasses = cn(styles.module, {
    [styles.shrink]: ui.complexDrawer.isOpen,
  });

  return (
    <div className={moduleClasses}>
      <p className={styles.title}>{house.name}</p>

      <div className={styles.wrapper}>
        <ComplexFloorColumn start={house.startFloor} quantity={maxFloor} />
        <div className={styles.entrances}>
          {house.entrances.map((item) => (
            <ComplexEntrance key={item.id} entrance={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
