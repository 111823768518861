import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { logoutUser, selectUser } from 'features/user/user-slice';
import {
  closeLoginModal,
  openLoginModal,
  selectUi,
} from 'features/ui/ui-slice';
import styles from './header.module.scss';
import { LoginModal } from 'features/user/components/login-modal';
import { Button } from 'components/button';

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const ui = useSelector(selectUi);
  const user = useSelector(selectUser);

  const match = useRouteMatch({ path: '/objects/:id' });
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <header className={styles.module}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.left}>
            {match && (
              <Button label="Назад" onClick={() => history.push('/')} />
            )}
          </div>
          <div className={styles.right}>
            <p className={styles.name}>{user.name}</p>
            {user.type === 'guest' ? (
              <Button
                label="Войти"
                onClick={() => dispatch(openLoginModal())}
              />
            ) : (
              <Button label="Выйти" onClick={handleLogout} />
            )}
            <LoginModal
              isOpen={ui.loginModalIsOpen}
              onClose={() => dispatch(closeLoginModal())}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
