import { FC } from 'react';
import cn from 'classnames';
import { IEntity, IQuarter, ISite, IVillage } from 'features/objects/types';
import styles from './village-site.module.scss';
import { VillageEntity } from 'features/objects/components/village-entity';

interface Props {
  site: ISite;
  quarter: IQuarter;
}

export const VillageSite: FC<Props> = ({ site, quarter }) => {
  const moduleClasses = cn(styles.module, {
    [styles.townhouse]: site.type === 'townhouses',
  });

  return (
    <div className={moduleClasses}>
      <div className={styles.number}>{site.number}</div>
      <div className={styles.name}>{site.name}</div>
      <div className={styles.items}>
        {site.entities.length && site.type === 'house' ? (
          <VillageEntity entity={site.entities[0]} quarter={quarter} />
        ) : (
          site.entities.map((item) => (
            <VillageEntity key={item.id} entity={item} quarter={quarter} />
          ))
        )}
      </div>
    </div>
  );
};
