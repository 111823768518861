import { FC, TextareaHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './textarea.module.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  moduleStyles?: string;
}

export const Textarea: FC<Props> = ({ label, moduleStyles, ...props }) => {
  const moduleClasses = cn(styles.module, moduleStyles);

  return (
    <label className={moduleClasses}>
      <span className={styles.label}>{label}</span>
      <textarea className={styles.textarea} {...props} />
    </label>
  );
};
