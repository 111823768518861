import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './object-item.module.scss';
import { domain } from 'api/constants';

interface Props {
  id: string;
  image: string;
  name: string;
}

export const ObjectItem: FC<Props> = ({ id, image, name }) => {
  const goodName = name.replace(/&quot;/g, '"');
  return (
    <Link className={styles.module} to={`/objects/${id}`}>
      <div className={styles.image}>
        <img src={`${domain}${image}`} alt={goodName} />
      </div>
      <p className={styles.name}>{goodName}</p>
    </Link>
  );
};
