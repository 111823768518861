import { FC } from 'react';
import { createPortal } from 'react-dom';
import styles from './drawer.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer: FC<Props> = ({ isOpen, onClose, children }) => {
  const portalEl = document.getElementById('portal');

  if (!isOpen || !portalEl) {
    return null;
  }

  const drawer = (
    <div className={styles.module}>
      <button
        className={styles.close}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            onClose();
          }
        }}
      >
        ✕
      </button>
      <div className={styles.content}>{children}</div>
    </div>
  );

  return createPortal(drawer, portalEl);
};
