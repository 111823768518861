import { FC } from 'react';
import cn from 'classnames';
import { useAppDispatch } from 'redux/store';
import { openVillageDrawer } from 'features/ui/ui-slice';
import { IEntity, IQuarter, IVillage } from 'features/objects/types';
import styles from './village-entity.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/user/user-slice';
import { getHiddenStatus } from 'utils/entity';

interface Props {
  entity: IEntity;
  quarter: IQuarter;
}

export const VillageEntity: FC<Props> = ({ entity, quarter }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const isStaff =
    user.type === 'admin' ||
    user.type === 'mainManager' ||
    user.type === 'manager';

  // const isAccessible = isStaff || user.type === 'partner';
  const isAccessible = isStaff;

  const {
    id,
    name,
    rooms,
    roomArea,
    roomPrice,
    landArea,
    landPrice,
    project,
    status,
    repairType,
  } = entity;

  const moduleClasses = cn(
    styles.module,
    `bg-${isStaff ? status : getHiddenStatus(status)}`,
    {
      [styles.townhouse]: entity.type === 'Таунхаус',
    }
  );

  const currentProject = quarter.projects.find((item) => item.id === project);

  let price = landPrice;
  if (repairType === '103' && currentProject?.withoutFinishing) {
    // Без внутренней отделки
    price = price + currentProject.withoutFinishing;
  } else if (repairType === '104' && currentProject?.roomPrice) {
    // С внутренней отделкой
    price = price + currentProject.roomPrice;
  } else if (
    repairType === '105' &&
    currentProject?.roomPrice &&
    currentProject.repair
  ) {
    // С ремонтом
    price = price + currentProject.roomPrice + currentProject.repair;
  }

  return (
    <>
      <div
        className={moduleClasses}
        onClick={() => {
          dispatch(openVillageDrawer(id));
        }}
      >
        <span className={styles.item}>{landArea} сот.</span>
        <span className={styles.item}>
          {isAccessible && `${landPrice.toLocaleString()} ₽`}
        </span>
        <span className={styles.item}>{currentProject?.name}</span>
        <span className={styles.item}>{roomArea} м²</span>
        <span className={styles.item}>
          {isAccessible && `${price.toLocaleString()} ₽`}
        </span>
      </div>
    </>
  );
};
