import { IComplex, IObject, IVillage } from 'features/objects/types';

export const findFlatById = (objects: IObject[], id: string | null) => {
  if (!id) {
    return null;
  }

  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];

    if (!object.complex) {
      continue;
    }

    const activeHouse = object.complex.houses.find((item) => item.isActive);

    if (!activeHouse) {
      continue;
    }

    for (let j = 0; j < activeHouse.entrances.length; j++) {
      const entrance = activeHouse.entrances[j];

      for (let k = 0; k < entrance.floors.length; k++) {
        const floor = entrance.floors[k];

        for (let l = 0; l < floor.flats.length; l++) {
          const flat = floor.flats[l];

          if (flat.id === id) {
            const layoutObj = object.complex.layouts.find(
              (item) => item.id === flat.layout,
            );
            return {
              ...flat,
              layout: layoutObj?.image,
              link3d: layoutObj?.link3d,
            };
          }
        }
      }
    }
  }

  return null;
};

export const findEntityById = (objects: IObject[], id: string | null) => {
  if (!id) {
    return null;
  }

  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];

    if (!object.village) {
      continue;
    }

    const activeQueue = object.village.queues.find((item) => item.isActive);

    if (!activeQueue) {
      continue;
    }

    for (let j = 0; j < activeQueue.quarters.length; j++) {
      const quarter = activeQueue.quarters[j];

      for (let k = 0; k < quarter.sites.length; k++) {
        const site = quarter.sites[k];

        for (let l = 0; l < site.entities.length; l++) {
          const entity = site.entities[l];

          if (entity.id === id) {
            return { entity, village: object.village, quarter };
          }
        }
      }
    }
  }

  return null;
};

export const calculateFlatsStatuses = (complex: IComplex) => {
  const result = {
    '44': 0,
    '45': 0,
    '46': 0,
    '47': 0,
    '48': 0,
    '49': 0,
    '50': 0,
    '51': 0,
    '52': 0,
  };

  complex.houses.forEach((house) =>
    house.entrances.forEach((entrance) =>
      entrance.floors.forEach((floor) =>
        floor.flats.forEach((flat) => result[flat.status]++),
      ),
    ),
  );

  return result;
};

export const calculateEntityStatuses = (village: IVillage) => {
  const result = {
    '75': 0,
    '76': 0,
    '77': 0,
    '78': 0,
    '79': 0,
    '80': 0,
    '81': 0,
    '82': 0,
    '83': 0,
  };

  village.queues.forEach((queue) =>
    queue.quarters.forEach((quarter) =>
      quarter.sites.forEach((site) =>
        site.entities.forEach((entity) => result[entity.status]++),
      ),
    ),
  );

  return result;
};
