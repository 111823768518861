import axios from 'axios';
import { domain } from './constants';
import { UserResponse, ErrorResponse } from './types';

const apiClient = axios.create({
  baseURL: `${domain}/api/user`,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const userAPI = {
  authorize: async () => {
    try {
      const { data } = await apiClient.post<UserResponse | ErrorResponse>(
        '/authorize.php'
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },

  login: async (login: string, password: string) => {
    try {
      const { data } = await apiClient.post<UserResponse | ErrorResponse>(
        '/login.php',
        { login, password }
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      const { data } = await apiClient.post<UserResponse | ErrorResponse>(
        '/logout.php'
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
};
