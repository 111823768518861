import { FC } from 'react';
import styles from './complex-floor-column.module.scss';

interface Props {
  start: number;
  quantity: number;
}

export const ComplexFloorColumn: FC<Props> = ({ start, quantity }) => {
  const floors = Array(quantity)
    .fill(0)
    .map((e, i) => i + start);

  return (
    <div className={styles.module}>
      {floors.map((item) => (
        <span className={styles.floor} key={item}>
          {item}
        </span>
      ))}
    </div>
  );
};
