import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { fetchObject, selectObjects } from 'features/objects/objects-slice';
import styles from './object-page.module.scss';
import { ObjectDetail } from 'features/objects/components/object-detail';

interface Params {
  id: string;
}

export const ObjectPage: FC = () => {
  const params = useParams<Params>();
  const dispatch = useAppDispatch();

  const objects = useSelector(selectObjects);
  const currentObject = objects.items.find((item) => item.id === params.id);

  useEffect(() => {
    if (!currentObject || !currentObject.isFull) {
      dispatch(fetchObject(params.id));
    }
  }, [dispatch, currentObject, params.id]);

  if (!currentObject) {
    return null;
  }

  return (
    <main className={styles.module}>
      <div className={styles.content}>
        <ObjectDetail object={currentObject} />
      </div>
    </main>
  );
};
