import { IFloor } from 'features/objects/types';
import { FC } from 'react';
import { ComplexFlat } from '../complex-flat';
import styles from './complex-floor.module.scss';

interface Props {
  floor: IFloor;
}

export const ComplexFloor: FC<Props> = ({ floor }) => {
  return (
    <div className={styles.module}>
      <div className={styles.flats}>
        {floor.flats.map((item) => (
          <ComplexFlat key={item.id} flat={item} />
        ))}
      </div>
    </div>
  );
};
