import { selectUser } from 'features/user/user-slice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styles from './statuses.module.scss';

interface Props {
  type: 'complex' | 'village';
  data: any;
}

export const Statuses: FC<Props> = ({ type, data }) => {
  const user = useSelector(selectUser);

  const isStaff =
    user.type === 'admin' ||
    user.type === 'mainManager' ||
    user.type === 'manager';

  return (
    <div className={styles.module}>
      <div className={styles.item}>
        <i className="bg-44"></i>
        <span>
          Свободно <b>{data[type === 'complex' ? '44' : '75']}</b>
        </span>
      </div>
      <div className={styles.item}>
        <i className="bg-45"></i>
        <span>
          Продано{' '}
          <b>
            {isStaff
              ? data[type === 'complex' ? '45' : '76']
              : +data[type === 'complex' ? '45' : '76'] +
                +data[type === 'complex' ? '47' : '78']}
          </b>
        </span>
      </div>
      <div className={styles.item}>
        <i className="bg-48"></i>
        <span>
          Забронировано <b>{data[type === 'complex' ? '48' : '79']}</b>
        </span>
      </div>

      <div className={styles.item}>
        <i className="bg-51"></i>
        <span>
          Переуступка <b>{data[type === 'complex' ? '51' : '82']}</b>
        </span>
      </div>

      {isStaff && (
        <>
          <div className={styles.item}>
            <i className="bg-46"></i>
            <span>
              Оформление ДДУ <b>{data[type === 'complex' ? '46' : '77']}</b>
            </span>
          </div>
          <div className={styles.item}>
            <i className="bg-47"></i>
            <span>
              Оформлено <b>{data[type === 'complex' ? '47' : '78']}</b>
            </span>
          </div>
          <div className={styles.item}>
            <i className="bg-49"></i>
            <span>
              Расторжение <b>{data[type === 'complex' ? '49' : '80']}</b>
            </span>
          </div>
          <div className={styles.item}>
            <i className="bg-50"></i>
            <span>
              Квартира сотрудников{' '}
              <b>{data[type === 'complex' ? '50' : '81']}</b>
            </span>
          </div>
          <div className={styles.item}>
            <i className="bg-52"></i>
            <span>
              Юр. услуги оплачены{' '}
              <b>{data[type === 'complex' ? '52' : '83']}</b>
            </span>
          </div>
        </>
      )}

      {isStaff && (
        <div className={styles.item}>
          <i className="bg-without-pv"></i>
          <span>Без ПВ</span>
        </div>
      )}
    </div>
  );
};
