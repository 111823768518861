import { FC } from 'react';
// import cn from 'classnames';
// import { IQuarter } from 'features/objects/types';
import styles from './village-legend.module.scss';
// import { VillageSite } from 'features/objects/components/village-site';

// interface Props {
//   quarter: IQuarter;
// }

export const VillageLegend: FC = () => {
  // const moduleClasses = cn(styles.module, {
  //   [styles.townhouse]: quarter.type === 'townhouses',
  // });

  return (
    <div className={styles.module}>
      <span className={styles.item}>№ (кад-й)</span>
      <span className={styles.item}>№дома и ул.</span>
      <span className={styles.item}>S участка</span>
      <span className={styles.item}>стоимость участка</span>
      <span className={styles.item}>№ проекта</span>
      <span className={styles.item}>S дома</span>
      <span className={styles.item}>Стоимость</span>
      {/* <span className={styles.item}>Для З/У</span> */}
      {/* <span className={styles.item}>оплата ком-ции</span> */}
    </div>
  );
};
