import { FC } from 'react';
import { IEntrance } from 'features/objects/types';
import styles from './complex-entrance.module.scss';
import { ComplexFloor } from '../complex-floor';

interface Props {
  entrance: IEntrance;
}

export const ComplexEntrance: FC<Props> = ({ entrance }) => {
  return (
    <div className={styles.module}>
      <p className={styles.title}>{entrance.name}</p>

      <div className={styles.floors}>
        {entrance.floors.map((item) => (
          <ComplexFloor key={item.id} floor={item} />
        ))}
      </div>
    </div>
  );
};
