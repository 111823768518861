import { FC } from 'react';
import { IObject } from 'features/objects/types';
import styles from './object-detail.module.scss';
import { Complex } from 'features/objects/components/complex';
import { Village } from 'features/objects/components/village';

interface Props {
  object: IObject;
}

export const ObjectDetail: FC<Props> = ({ object }) => {
  return (
    <div className={styles.module}>
      <h3 className={styles.name}>
        <span>
          {object.type === 'complex' ? 'Жилой комплекс' : 'Коттеджный поселок'}:
        </span>
        <b>{object.name.replace(/&quot;/g, '"')}</b>
      </h3>
      {object.type === 'complex'
        ? object.complex && <Complex complex={object.complex} />
        : object.village && <Village village={object.village} />}
    </div>
  );
};
