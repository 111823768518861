import { ButtonHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  moduleStyles?: string;
}

export const Button: FC<Props> = ({ label, moduleStyles, ...props }) => {
  const moduleClasses = cn(styles.module, moduleStyles);

  return (
    <button className={moduleClasses} {...props}>
      {label}
    </button>
  );
};
