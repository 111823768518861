import { FC } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Modal: FC<Props> = ({ isOpen, onClose, children }) => {
  if (!isOpen || !portalEl) {
    return null;
  }

  const modal = (
    <div
      className={styles.module}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );

  return createPortal(modal, portalEl);
};
