import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { fetchObjects, selectObjects } from 'features/objects/objects-slice';
import styles from './home.module.scss';
import { ObjectItem } from 'features/objects/components/object-item';

export const Home: FC = () => {
  const dispatch = useAppDispatch();
  const objects = useSelector(selectObjects);

  useEffect(() => {
    if (!objects.isLoaded) {
      dispatch(fetchObjects());
    }
  }, [dispatch, objects.isLoaded]);

  return (
    <main className={styles.module}>
      <div className={styles.content}>
        <div className="container">
          <div className="row">
            {objects.items.map((item) => (
              <div className="col-4" key={item.id}>
                <ObjectItem id={item.id} image={item.image} name={item.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};
