import axios from 'axios';
import { domain } from './constants';
import { ErrorResponse } from './types';
import { Certificates } from 'features/certificates/types';

const apiClient = axios.create({
  baseURL: `${domain}/api/certificates`,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const certificatesAPI = {
  getCertificates: async () => {
    try {
      const { data } = await apiClient.post<Certificates | ErrorResponse>(
        '/fetch.php',
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
};
