import { FC, SelectHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './select.module.scss';

export interface IOption {
  [key: string]: string;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: IOption;
  moduleStyles?: string;
}

export const Select: FC<Props> = ({
  label,
  options,
  moduleStyles,
  ...props
}) => {
  const moduleClasses = cn(styles.module, moduleStyles);

  return (
    <label className={moduleClasses}>
      <span className={styles.label}>{label}</span>
      <select className={styles.select} {...props}>
        {Object.keys(options).map((item) => (
          <option key={item} value={item}>
            {options[item]}
          </option>
        ))}
      </select>
    </label>
  );
};
