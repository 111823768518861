import { FlatStatusType } from 'features/objects/types';

export const flatTinyTypes = {
  '37': 'С',
  '38': '1к',
  '39': '2к',
  '40': '3к',
  '41': '2е',
  '42': '3е',
  '43': 'К',
};

export const flatTypes = {
  '37': 'Студия',
  '38': '1 - комнатная',
  '39': '2 - комнатная',
  '40': '3 - комнатная',
  '41': '2е',
  '42': '3е',
  '43': 'Коммерция',
};

export const getHiddenStatus = (status: FlatStatusType) => {
  switch (status) {
    case '46':
      return '45';
    case '47':
      return '45';
    case '49':
      return '45';
    case '50':
      return '45';
    case '52':
      return '45';
    default:
      return status;
  }
};
