import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { objectsAPI } from 'api/objects';
import { EditFlatSubmitData, EditEntitySubmitData } from 'api/types';
import { AppThunk, RootState } from 'redux/store';
import { IEntity, IFlat, IObject, ObjectsState } from './types';

const initialState: ObjectsState = {
  isLoaded: false,
  items: [],
};

export const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    setObjects: (state, { payload }: PayloadAction<IObject[]>) => {
      if (state.items.length === 0) {
        state.items = state.items.concat(payload);
      } else {
        const existingId = state.items[0].id;
        const newItems = payload.filter((item) => item.id !== existingId);
        state.items = state.items.concat(newItems);
      }

      state.isLoaded = true;
    },
    setObject: (state, { payload }: PayloadAction<IObject>) => {
      const idx = state.items.findIndex((item) => item.id === payload.id);

      if (idx !== -1) {
        const newItems = [
          ...state.items.slice(0, idx),
          payload,
          ...state.items.slice(idx + 1),
        ];
        state.items = newItems;
      } else {
        state.items.push(payload);
      }
    },
    setHouseActive: (state, { payload }: PayloadAction<string>) => {
      state.items.forEach((object) =>
        object.complex?.houses.forEach((house) => {
          if (house.id === payload) {
            if (!house.isActive) {
              house.isActive = true;
            }
          } else if (house.isActive) {
            house.isActive = false;
          }
        }),
      );
    },
    setFlat: (state, { payload }: PayloadAction<IFlat>) => {
      state.items.forEach((object) =>
        object.complex?.houses.forEach((house) =>
          house.entrances.forEach((entrance) =>
            entrance.floors.forEach((floor) =>
              floor.flats.forEach((flat) => {
                if (flat.id === payload.id) {
                  flat.comment = payload.comment;
                  flat.status = payload.status;
                  flat.price = payload.price;
                  flat.bookManager = payload.bookManager;
                  flat.dduManager = payload.dduManager;
                  flat.legalCategory = payload.legalCategory;
                  flat.placeAtAvito = payload.placeAtAvito;
                  flat.withoutPV = payload.withoutPV;
                  flat.blueMark = payload.blueMark;
                  flat.redMark = payload.redMark;
                  flat.orangeMark = payload.orangeMark;
                }
              }),
            ),
          ),
        ),
      );
    },
    setEntity: (state, { payload }: PayloadAction<IEntity>) => {
      state.items.forEach((object) =>
        object.village?.queues.forEach((queue) =>
          queue.quarters.forEach((quarter) =>
            quarter.sites.forEach((site) =>
              site.entities.forEach((entity) => {
                if (entity.id === payload.id) {
                  entity.roomArea = payload.roomArea;
                  entity.roomPrice = payload.roomPrice;
                  entity.comment = payload.comment;
                  entity.status = payload.status;
                  entity.project = payload.project;
                  entity.bookManager = payload.bookManager;
                  entity.dduManager = payload.dduManager;
                  entity.legalCategory = payload.legalCategory;
                  entity.repairType = payload.repairType;
                }
              }),
            ),
          ),
        ),
      );
    },
  },
});

export const fetchObjects = (): AppThunk => async (dispatch) => {
  try {
    const objects = await objectsAPI.getObjects();
    dispatch(setObjects(objects));
  } catch (error) {}
};

export const fetchObject =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const object = await objectsAPI.getObject(id);
      dispatch(setObject(object));
    } catch (error) {}
  };

export const fetchEditFlat =
  (submitData: EditFlatSubmitData): AppThunk =>
  async (dispatch) => {
    try {
      const flat = await objectsAPI.editFlat(submitData);
      dispatch(setFlat(flat));
    } catch (error) {}
  };

export const fetchEditEntity =
  (submitData: EditEntitySubmitData): AppThunk =>
  async (dispatch) => {
    try {
      const entity = await objectsAPI.editEntity(submitData);
      dispatch(setEntity(entity));
    } catch (error) {}
  };

export const { setObjects, setObject, setHouseActive, setFlat, setEntity } =
  objectsSlice.actions;

export const selectObjects = (state: RootState) => state.objects;

export const objectsReducer = objectsSlice.reducer;
