import { FC } from 'react';
import { IHouse } from 'features/objects/types';
import styles from './complex-house-selector.module.scss';
import { Button } from 'components/button';
import { useAppDispatch } from 'redux/store';
import { setHouseActive } from 'features/objects/objects-slice';

interface Props {
  houses: IHouse[];
}

export const ComplexHouseSelector: FC<Props> = ({ houses }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.module}>
      <p className={styles.title}>Выбрать дом</p>
      <div className={styles.buttons}>
        {houses.map((item) => (
          <Button
            moduleStyles={styles.button}
            key={item.id}
            label={item.name}
            onClick={() => dispatch(setHouseActive(item.id))}
          />
        ))}
      </div>
    </div>
  );
};
