import { FC, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { authorizeUser } from 'features/user/user-slice';
import { fetchManagers } from 'features/managers/managers-slice';
import styles from './app.module.scss';
import { Home } from 'pages/home';
import { ObjectPage } from 'pages/object-page';
import { Header } from 'features/ui/components/header';
import { fetchCertificates } from 'features/certificates/certificates-slice';

export const App: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authorizeUser());
    dispatch(fetchManagers());
    // dispatch(fetchCertificates());

    // dispatch(
    //   setUser({
    //     id: '1',
    //     login: 'test',
    //     email: 'test@mail.test',
    //     name: 'vasya',
    //     lastName: 'pupkin',
    //     type: 'admin',
    //   })
    // );
  }, [dispatch]);

  return (
    <div className={styles.module}>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/objects/:id">
            <ObjectPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};
