import { FC, Fragment } from 'react';
import cn from 'classnames';
import { IEntity, IQuarter, IVillage } from 'features/objects/types';
import styles from './village-quarter.module.scss';
import { VillageSite } from 'features/objects/components/village-site';
import { VillageLegend } from '../village-legend';

interface Props {
  quarter: IQuarter;
}

export const VillageQuarter: FC<Props> = ({ quarter }) => {
  const moduleClasses = cn(styles.module, {
    [styles.townhouse]: quarter.type === 'townhouses',
  });

  return (
    <div className={moduleClasses}>
      <p className={styles.title}>{quarter.name}</p>

      <div className={styles.items}>
        {quarter.sites.map((item, idx) => {
          if (item.type === 'townhouses') {
            return (
              <Fragment key={item.id}>
                <VillageLegend />
                <VillageSite site={item} quarter={quarter} />
              </Fragment>
            );
          }

          if (idx % 10 === 0) {
            return (
              <Fragment key={item.id}>
                <VillageLegend />
                <VillageSite site={item} quarter={quarter} />
              </Fragment>
            );
          }

          return <VillageSite key={item.id} site={item} quarter={quarter} />;
        })}
      </div>
    </div>
  );
};
