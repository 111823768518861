import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { managersAPI } from 'api/managers';
import { AppThunk, RootState } from 'redux/store';
import { IManager, ManagersState } from './types';

const initialState: ManagersState = {
  items: [],
};

export const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers: {
    setManagers: (state, { payload }: PayloadAction<IManager[]>) => {
      state.items = state.items.concat(payload);
    },
  },
});

export const fetchManagers = (): AppThunk => async (dispatch) => {
  try {
    const managers = await managersAPI.getManagers();
    dispatch(setManagers(managers));
  } catch (error) {}
};

export const { setManagers } = managersSlice.actions;

export const selectManagers = (state: RootState) => state.managers;

export const managersReducer = managersSlice.reducer;
