import { FC } from 'react';
import { useAppDispatch } from 'redux/store';
import { openComplexModal } from 'features/ui/ui-slice';
import { IFlat } from 'features/objects/types';
import styles from './complex-drawer.module.scss';
import { Drawer } from 'components/drawer';
import { Button } from 'components/button';
import { flatTypes } from 'utils/flat';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/user/user-slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentFlat: IFlat;
}

export const ComplexDrawer: FC<Props> = ({ isOpen, onClose, currentFlat }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const { name, type, area, hasRepair, comment, layout, link3d } = currentFlat;

  const isMainStaff = user.type === 'admin' || user.type === 'mainManager';
  const isStaff = isMainStaff || user.type === 'manager';

  console.log('layout');
  console.log(layout);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.module}>
        <div className={styles.image}>
          {layout && <img src={layout} alt={name} />}
        </div>

        <div className={styles.block}>
          <p className={styles.item}>
            <b>Название</b>
            <span>{name}</span>
          </p>
          <p className={styles.item}>
            <b>Тип</b>
            <span>{flatTypes[type]}</span>
          </p>
          <p className={styles.item}>
            <b>Площадь</b>
            <span>{area}м²</span>
          </p>
          <p className={styles.item}>
            <b>Ремонт</b>
            <span>{hasRepair === '35' ? 'есть' : 'нет'}</span>
          </p>
        </div>

        {comment && isStaff && (
          <div className={styles.comment}>
            <span>Комментарий</span>
            <p>{comment}</p>
          </div>
        )}

        <div className={styles.actions}>
          {isMainStaff && (
            <Button
              label="Изменить"
              onClick={() => dispatch(openComplexModal())}
            />
          )}

          {link3d && (
            <a
              className={styles.link3d}
              href={`https://widget.planoplan.com/${link3d}`}
              target="_blank"
              rel="noreferrer"
            >
              3D тур
            </a>
          )}
        </div>
      </div>
    </Drawer>
  );
};
