import { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { selectManagers } from 'features/managers/managers-slice';
import { fetchEditFlat } from 'features/objects/objects-slice';
import { closeComplexModal } from 'features/ui/ui-slice';
import {
  FlatLegalCategoryType,
  FlatStatusType,
  IFlat,
} from 'features/objects/types';
import { EditFlatSubmitData } from 'api/types';
import styles from './complex-modal.module.scss';
import { Modal } from 'components/modal';
import { IOption, Select } from 'components/select';
import { Textarea } from 'components/textarea';
import { Button } from 'components/button';
import { statusFlatOptions, legalFlatOptions } from 'utils/entity';
import { flatTypes } from 'utils/flat';
import { Input } from 'components/input';
import { selectUser } from 'features/user/user-slice';
import {
  clearCertificates,
  fetchCertificates,
  selectCertificates,
} from 'features/certificates/certificates-slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentFlat: IFlat;
}

export const ComplexModal: FC<Props> = ({ isOpen, onClose, currentFlat }) => {
  const dispatch = useAppDispatch();
  const managers = useSelector(selectManagers);
  const user = useSelector(selectUser);

  const certificates = useSelector(selectCertificates);

  const isAdmin = user.type === 'admin';
  const isMainManager = user.type === 'mainManager';

  useEffect(() => {
    if (isAdmin || isMainManager) {
      dispatch(fetchCertificates());
    }
    return () => {
      dispatch(clearCertificates());
    };
  }, [dispatch, isAdmin, isMainManager]);

  const [status, setStatus] = useState(currentFlat.status);
  const [price, setPrice] = useState(currentFlat.price);
  const [dduManager, setDduManager] = useState(currentFlat.dduManager);
  const [bookManager, setBookManager] = useState(currentFlat.bookManager);
  const [legalCategory, setLegalCategory] = useState(currentFlat.legalCategory);
  const [comment, setComment] = useState(currentFlat.comment);
  const [placeAtAvito, setPlaceAtAvito] = useState(currentFlat.placeAtAvito);
  const [withoutPV, setWithoutPV] = useState(currentFlat.withoutPV);
  const [blueMark, setBlueMark] = useState(currentFlat.blueMark);
  const [redMark, setRedMark] = useState(currentFlat.redMark);
  const [orangeMark, setOrangeMark] = useState(currentFlat.orangeMark);
  const [certificate, setCertificate] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitData: EditFlatSubmitData = {
      id: currentFlat.id,
      status,
      price,
      dduManager,
      bookManager,
      legalCategory,
      comment,
      placeAtAvito,
      withoutPV,
      blueMark,
      redMark,
      orangeMark,
      certificate,
    };

    dispatch(fetchEditFlat(submitData)).then(() => {
      dispatch(closeComplexModal());
    });
  };

  const existingCertificate = certificates.items.find(
    (item) => item.recipientFlat === currentFlat.id,
  );

  let certificateMarkup = null;

  if (existingCertificate) {
    certificateMarkup = (
      <div className={styles.certificates}>
        Передан сертификат № {existingCertificate.name}
      </div>
    );
  } else if (certificates.items.length > 0) {
    certificateMarkup = (
      <div className={styles.certificates}>
        <Select
          moduleStyles={styles.status}
          label="Сертификаты"
          options={certificates.items
            .filter((item) => !item.recipientFlat && !item.recipientEntity)
            .reduce<Record<string, string>>(
              (accumulator, current) => {
                return { ...accumulator, [current.id]: current.name };
              },
              { '0': 'Выбрать сертификат' },
            )}
          value={certificate}
          onChange={(event) => {
            const { value } = event.target;
            setCertificate(value === '0' ? '' : value);
          }}
        />
      </div>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.module}>
        <button className={styles.close} onClick={onClose}>
          ✕
        </button>
        <div className={styles.content}>
          <p className={styles.title}>
            Изменить {flatTypes[currentFlat.type]} {currentFlat.name}
          </p>
          <form onSubmit={handleSubmit}>
            <Select
              moduleStyles={styles.status}
              label="Статус"
              options={statusFlatOptions}
              value={status}
              onChange={(event) => {
                setDduManager(null);
                setBookManager(null);
                setLegalCategory(null);
                setStatus(event.target.value as FlatStatusType);
              }}
            />
            {status === '46' && (
              <Select
                moduleStyles={styles.status}
                label="Кем"
                options={managers.items.reduce((obj: IOption, item) => {
                  obj[item.id] = `${item.name} ${item.lastName}`;
                  return obj;
                }, {})}
                value={dduManager ? dduManager : ''}
                onChange={(event) => setDduManager(event.target.value)}
              />
            )}
            {status === '48' && (
              <Select
                moduleStyles={styles.status}
                label="Кем"
                options={managers.items.reduce((obj: IOption, item) => {
                  obj[item.id] = `${item.name} ${item.lastName}`;
                  return obj;
                }, {})}
                value={bookManager ? bookManager : ''}
                onChange={(event) => setBookManager(event.target.value)}
              />
            )}
            {status === '52' && (
              <Select
                moduleStyles={styles.status}
                label="Категория юр. услуг"
                options={legalFlatOptions}
                value={legalCategory ? legalCategory : ''}
                onChange={(event) =>
                  setLegalCategory(event.target.value as FlatLegalCategoryType)
                }
              />
            )}
            <Input
              moduleStyles={styles.roomPrice}
              label="Цена"
              type="number"
              min="0"
              step="0.01"
              value={price === 0 ? '' : price}
              onChange={(event) => setPrice(+event.target.value)}
            />
            <Textarea
              moduleStyles={styles.comment}
              label="Комментарий"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />

            {isAdmin && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={placeAtAvito === '106'}
                    onChange={(event) =>
                      setPlaceAtAvito(event.target.checked ? '106' : '107')
                    }
                  />
                  <span>Разместить на Авито</span>
                </label>
              </div>
            )}

            {isMainManager && (
              <>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={withoutPV === '108'}
                      onChange={(event) =>
                        setWithoutPV(event.target.checked ? '108' : '109')
                      }
                    />
                    <span>Без первоначального взноса</span>
                  </label>
                </div>

                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={blueMark === '112'}
                      onChange={(event) =>
                        setBlueMark(event.target.checked ? '112' : '113')
                      }
                    />
                    <span>Синяя метка</span>
                  </label>
                </div>

                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={redMark === '114'}
                      onChange={(event) =>
                        setRedMark(event.target.checked ? '114' : '115')
                      }
                    />
                    <span>Красная метка</span>
                  </label>
                </div>

                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={orangeMark === '116'}
                      onChange={(event) =>
                        setOrangeMark(event.target.checked ? '116' : '117')
                      }
                    />
                    <span>Оранжевая метка</span>
                  </label>
                </div>
              </>
            )}

            {certificateMarkup}

            <Button label="Изменить" moduleStyles={styles.submit} />
          </form>
        </div>
      </div>
    </Modal>
  );
};
