import { configureStore, Action } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { uiReducer } from 'features/ui/ui-slice';
import { userReducer } from 'features/user/user-slice';
import { managersReducer } from 'features/managers/managers-slice';
import { objectsReducer } from 'features/objects/objects-slice';
import { certificatesReducer } from 'features/certificates/certificates-slice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    managers: managersReducer,
    objects: objectsReducer,
    certificates: certificatesReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
