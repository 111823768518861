import { FC } from 'react';
import cn from 'classnames';
import { useAppDispatch } from 'redux/store';
import { IFlat } from 'features/objects/types';
import styles from './complex-flat.module.scss';
import { flatTinyTypes, getHiddenStatus } from 'utils/flat';
import { openComplexDrawer } from 'features/ui/ui-slice';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/user/user-slice';

interface Props {
  flat: IFlat;
}

export const ComplexFlat: FC<Props> = ({ flat }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const isStaff =
    user.type === 'admin' ||
    user.type === 'mainManager' ||
    user.type === 'manager';

  const isAccessible = isStaff || user.type === 'partner';

  const {
    id,
    type,
    area,
    name,
    price,
    status,
    withoutPV,
    blueMark,
    redMark,
    orangeMark,
  } = flat;

  const moduleClasses = cn(
    styles.module,
    `bg-${isStaff ? status : getHiddenStatus(status)}`,
    {
      [styles.withoutPV]: withoutPV === '108' && isStaff,
      [styles.blueMark]: blueMark === '112' && isStaff,
      [styles.redMark]: redMark === '114' && isStaff,
      [styles.orangeMark]: orangeMark === '116' && isStaff,
    },
  );

  return (
    <div
      className={moduleClasses}
      onClick={() => {
        dispatch(openComplexDrawer(id));
      }}
    >
      <p className={styles.top}>
        <span className={styles.type}>{flatTinyTypes[type]}</span>
        <span className={styles.area}>{area}м²</span>
      </p>
      <p className={styles.bottom}>
        <span className={styles.name}>{name}</span>
        <span className={styles.price}>
          {isAccessible && `${Math.round(price * area).toLocaleString()} ₽`}
        </span>
      </p>
    </div>
  );
};
