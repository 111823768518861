import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Certificates } from './types';
import { AppThunk, RootState } from 'redux/store';
import { certificatesAPI } from 'api/certificates';

const initialState: Certificates = {
  items: [],
};

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    setCertificates: (state, { payload }: PayloadAction<Certificates>) => {
      state.info = payload.info;
      state.items = payload.items;
    },

    clearCertificates: (state) => {
      state.info = undefined;
      state.items = [];
    },
  },
});

export const fetchCertificates = (): AppThunk => async (dispatch) => {
  try {
    const certificates = await certificatesAPI.getCertificates();
    dispatch(setCertificates(certificates));
  } catch (error) {}
};

export const { setCertificates, clearCertificates } = certificatesSlice.actions;

export const selectCertificates = (state: RootState) => state.certificates;

export const certificatesReducer = certificatesSlice.reducer;
