import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { openVillageModal } from 'features/ui/ui-slice';
import { IEntity, IQuarter, IVillage } from 'features/objects/types';
import styles from './village-drawer.module.scss';
import { Drawer } from 'components/drawer';
import { Button } from 'components/button';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/user/user-slice';
import { IOption, Select } from 'components/select';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentEntity: IEntity;
  currentQuarter: IQuarter;
}

export const VillageDrawer: FC<Props> = ({
  isOpen,
  onClose,
  currentEntity,
  currentQuarter,
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [project, setProject] = useState(currentEntity.project);

  useEffect(() => {
    setProject(currentEntity.project);
  }, [currentEntity.project]);

  const isMainStaff = user.type === 'admin' || user.type === 'mainManager';
  const isStaff = isMainStaff || user.type === 'manager';

  const { name, type, roomArea, landArea, rooms, floors, comment } =
    currentEntity;

  const currentProject = currentQuarter.projects.find(
    (item) => item.id === project
  );

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.module}>
        <div className={styles.image}>
          <img src="" alt="" />
        </div>

        <div className={styles.block}>
          <p className={styles.item}>
            <b>Название</b>
            <span>{name}</span>
          </p>
          <p className={styles.item}>
            <b>Тип</b>
            <span>{type}</span>
          </p>
          <p className={styles.item}>
            <b>Площадь помещения</b>
            <span>{roomArea}м²</span>
          </p>
          <p className={styles.item}>
            <b>Площадь участка</b>
            <span>{landArea}сот</span>
          </p>
          <p className={styles.item}>
            <b>Количество комнат</b>
            <span>{rooms}</span>
          </p>
          <p className={styles.item}>
            <b>Количество этажей</b>
            <span>{floors}</span>
          </p>
        </div>

        {comment && isStaff && (
          <div className={styles.comment}>
            <span>Комментарий</span>
            <p>{comment}</p>
          </div>
        )}

        {isMainStaff && (
          <div className={styles.actions}>
            <Button
              label="Изменить"
              onClick={() => dispatch(openVillageModal())}
            />
          </div>
        )}

        {isStaff && currentQuarter.projects.length > 0 && (
          <Select
            moduleStyles={styles.project}
            label="Проект"
            options={currentQuarter.projects.reduce(
              (obj: IOption, item) => {
                obj[item.id] = `${item.name} / ${
                  item.roomArea
                } м² / ${item.roomPrice.toLocaleString()} ₽`;
                return obj;
              },
              { '0': 'Не установлено' }
            )}
            value={project}
            onChange={(event) => {
              setProject(event.target.value === '0' ? '' : event.target.value);
            }}
          />
        )}
        {isStaff && currentProject && (
          <div className={styles.info}>
            <p>
              <strong>Без внутрренней отделки</strong>
              <span>{currentProject.withoutFinishing.toLocaleString()} ₽</span>
              <i>{currentEntity.landPrice.toLocaleString()} ₽</i>
              <strong>
                {(
                  currentEntity.landPrice + currentProject.withoutFinishing
                ).toLocaleString()}{' '}
                ₽
              </strong>
            </p>

            <p>
              <strong>С внутрренней отделкой</strong>
              <span>{currentProject.roomPrice.toLocaleString()} ₽</span>
              <i>{currentEntity.landPrice.toLocaleString()} ₽</i>
              <strong>
                {(
                  currentEntity.landPrice + currentProject.roomPrice
                ).toLocaleString()}{' '}
                ₽
              </strong>
            </p>

            <p>
              <strong>Стоимость ремонта</strong>
              <span>{currentProject.repair.toLocaleString()} ₽</span>
              <span>{currentProject.roomPrice.toLocaleString()} ₽</span>
              <i>{currentEntity.landPrice.toLocaleString()} ₽</i>
              <strong>
                {(
                  currentProject.repair +
                  currentEntity.landPrice +
                  currentProject.roomPrice
                ).toLocaleString()}{' '}
                ₽
              </strong>
            </p>
          </div>
        )}
      </div>
    </Drawer>
  );
};
