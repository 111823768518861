import { IOption } from 'components/select';
import { EntityStatusType } from 'features/objects/types';

export const statusFlatOptions: IOption = {
  '44': 'Свободно',
  '45': 'Продано',
  '46': 'Оформление ДДУ',
  '47': 'Оформлено',
  '48': 'Забронировано',
  '49': 'Расторжение',
  '50': 'Квартира сотрудников',
  '51': 'Переуступка',
  '52': 'Юр. услуги оплачены',
};

export const legalFlatOptions: IOption = {
  '53': 'Ипотека',
  '54': 'Местная сделка',
  '55': 'Дистанционная сделка',
};

export const statusEntityOptions: IOption = {
  '75': 'Свободно',
  '76': 'Продано',
  '77': 'Оформление ДДУ',
  '78': 'Оформлено',
  '79': 'Забронировано',
  '80': 'Расторжение',
  '81': 'Квартира сотрудников',
  '82': 'Переуступка',
  '83': 'Юр. услуги оплачены',
};

export const legalEntityOptions: IOption = {
  '84': 'Ипотека',
  '85': 'Местная сделка',
  '86': 'Дистанционная сделка',
};

export const getHiddenStatus = (status: EntityStatusType) => {
  switch (status) {
    case '77':
      return '76';
    case '78':
      return '76';
    case '80':
      return '76';
    case '81':
      return '76';
    case '83':
      return '76';
    default:
      return status;
  }
};
