import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { selectObjects } from 'features/objects/objects-slice';
import {
  closeVillageDrawer,
  closeVillageModal,
  selectUi,
} from 'features/ui/ui-slice';
import { IVillage } from 'features/objects/types';
import styles from './village.module.scss';
import { VillageDrawer } from 'features/objects/components/village-drawer';
import { VillageModal } from 'features/objects/components/village-modal';
import { VillageQueueSelector } from 'features/objects/components/village-queue-selector';
import { VillageQueue } from 'features/objects/components/village-queue';
import { calculateEntityStatuses, findEntityById } from 'utils/objects';
import { Statuses } from 'components/statuses';

interface Props {
  village: IVillage;
}

export const Village: FC<Props> = ({ village }) => {
  const dispatch = useAppDispatch();
  const ui = useSelector(selectUi);
  const objects = useSelector(selectObjects);

  const currentQueue = village.queues.find((item) => item.isActive);

  if (!currentQueue) {
    return null;
  }

  const entityStatuses = calculateEntityStatuses(village);

  const currentData = findEntityById(objects.items, ui.villageDrawer.entityId);

  return (
    <div className={styles.module}>
      <VillageQueueSelector queues={village.queues} />

      <Statuses type="village" data={entityStatuses} />

      <VillageQueue queue={currentQueue} />

      {currentData && (
        <VillageDrawer
          isOpen={ui.villageDrawer.isOpen}
          onClose={() => dispatch(closeVillageDrawer())}
          currentEntity={currentData.entity}
          currentQuarter={currentData.quarter}
        />
      )}
      {currentData && ui.villageModalIsOpen && (
        <VillageModal
          isOpen={ui.villageModalIsOpen}
          onClose={() => dispatch(closeVillageModal())}
          currentEntity={currentData.entity}
          currentQuarter={currentData.quarter}
        />
      )}
    </div>
  );
};
