import { FC, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { selectManagers } from 'features/managers/managers-slice';
import { fetchEditEntity } from 'features/objects/objects-slice';
import { closeVillageModal } from 'features/ui/ui-slice';
import {
  EntityLegalCategoryType,
  EntityStatusType,
  IEntity,
  IQuarter,
  IVillage,
} from 'features/objects/types';
import { EditEntitySubmitData } from 'api/types';
import styles from './village-modal.module.scss';
import { Modal } from 'components/modal';
import { IOption, Select } from 'components/select';
import { Input } from 'components/input';
import { Textarea } from 'components/textarea';
import { Button } from 'components/button';
import { statusEntityOptions, legalEntityOptions } from 'utils/entity';
import {
  clearCertificates,
  fetchCertificates,
  selectCertificates,
} from 'features/certificates/certificates-slice';
import { selectUser } from 'features/user/user-slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentEntity: IEntity;
  currentQuarter: IQuarter;
}

export const VillageModal: FC<Props> = ({
  isOpen,
  onClose,
  currentEntity,
  currentQuarter,
}) => {
  const dispatch = useAppDispatch();
  const managers = useSelector(selectManagers);
  const user = useSelector(selectUser);

  const certificates = useSelector(selectCertificates);

  const isAdmin = user.type === 'admin';
  const isMainManager = user.type === 'mainManager';

  useEffect(() => {
    if (isAdmin || isMainManager) {
      dispatch(fetchCertificates());
    }
    return () => {
      dispatch(clearCertificates());
    };
  }, [dispatch, isAdmin, isMainManager]);

  const [status, setStatus] = useState(currentEntity.status);
  const [dduManager, setDduManager] = useState(currentEntity.dduManager);
  const [bookManager, setBookManager] = useState(currentEntity.bookManager);
  const [legalCategory, setLegalCategory] = useState(
    currentEntity.legalCategory,
  );
  const [roomArea, setRoomArea] = useState(currentEntity.roomArea);
  const [roomPrice, setRoomPrice] = useState(currentEntity.roomPrice);
  const [comment, setComment] = useState(currentEntity.comment);
  const [project, setProject] = useState(currentEntity.project);
  const [repairType, setRepairType] = useState(currentEntity.repairType);
  const [certificate, setCertificate] = useState('');

  const currentProject = currentQuarter.projects.find(
    (item) => item.id === project,
  );

  useEffect(() => {
    if (currentProject) {
      setRoomArea(currentProject.roomArea);
      setRoomPrice(currentProject.roomPrice);
    }
  }, [currentProject, project]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitData: EditEntitySubmitData = {
      id: currentEntity.id,
      status,
      dduManager,
      bookManager,
      legalCategory,
      roomArea,
      roomPrice,
      comment,
      project: project === '0' ? '' : project,
      repairType: repairType ? repairType : '104',
      certificate,
    };

    dispatch(fetchEditEntity(submitData)).then(() => {
      dispatch(closeVillageModal());
    });
  };

  const existingCertificate = certificates.items.find(
    (item) => item.recipientEntity === currentEntity.id,
  );

  let certificateMarkup = null;

  if (existingCertificate) {
    certificateMarkup = (
      <div className={styles.certificates}>
        Передан сертификат № {existingCertificate.name}
      </div>
    );
  } else if (certificates.items.length > 0) {
    certificateMarkup = (
      <div className={styles.certificates}>
        <Select
          moduleStyles={styles.status}
          label="Сертификаты"
          options={certificates.items
            .filter((item) => !item.recipientFlat && !item.recipientEntity)
            .reduce<Record<string, string>>(
              (accumulator, current) => {
                return { ...accumulator, [current.id]: current.name };
              },
              { '0': 'Выбрать сертификат' },
            )}
          value={certificate}
          onChange={(event) => {
            const { value } = event.target;
            setCertificate(value === '0' ? '' : value);
          }}
        />
      </div>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.module}>
        <button className={styles.close} onClick={onClose}>
          ✕
        </button>
        <div className={styles.content}>
          <p className={styles.title}>
            Изменить {currentEntity.type} {currentEntity.name}
          </p>
          <form onSubmit={handleSubmit}>
            {currentQuarter.projects.length > 0 && (
              <Select
                moduleStyles={styles.project}
                label="Проект"
                options={currentQuarter.projects.reduce(
                  (obj: IOption, item) => {
                    obj[item.id] = `${item.name} / ${
                      item.roomArea
                    } м² / ${item.roomPrice.toLocaleString()} ₽`;
                    return obj;
                  },
                  { '0': 'Не установлено' },
                )}
                value={project}
                onChange={(event) => {
                  setProject(
                    event.target.value === '0' ? '' : event.target.value,
                  );
                }}
              />
            )}
            {currentProject && (
              <>
                <div className={styles.info}>
                  <p>
                    <strong>Площадь: </strong>
                    <span>{currentProject.roomArea}м²</span>
                  </p>
                  <p>
                    <strong>Цена: </strong>
                    <span>{currentProject.roomPrice}₽</span>
                  </p>
                </div>
                <div className={styles.prices}>
                  <label>
                    <strong>Без внутрренней отделки</strong>
                    <p>
                      <input
                        type="radio"
                        checked={repairType === '103'}
                        onChange={() => setRepairType('103')}
                      />
                      <span>
                        {(+currentProject.withoutFinishing).toLocaleString()} ₽
                      </span>
                    </p>
                  </label>

                  <label>
                    <strong>С внутрренней отделкой</strong>
                    <p>
                      <input
                        type="radio"
                        checked={!repairType || repairType === '104'}
                        onChange={() => setRepairType('104')}
                      />
                      <span>
                        {(+currentProject.roomPrice).toLocaleString()} ₽
                      </span>
                    </p>
                  </label>

                  <label>
                    <strong>Стоимость ремонта</strong>
                    <p>
                      <input
                        type="radio"
                        checked={repairType === '105'}
                        onChange={() => setRepairType('105')}
                      />
                      <span>{(+currentProject.repair).toLocaleString()} ₽</span>
                    </p>
                  </label>
                </div>
              </>
            )}
            <Select
              moduleStyles={styles.status}
              label="Статус"
              options={statusEntityOptions}
              value={status}
              onChange={(event) => {
                setDduManager(null);
                setBookManager(null);
                setLegalCategory(null);
                setStatus(event.target.value as EntityStatusType);
              }}
            />
            {status === '77' && (
              <Select
                moduleStyles={styles.status}
                label="Кем"
                options={managers.items.reduce((obj: IOption, item) => {
                  obj[item.id] = `${item.name} ${item.lastName}`;
                  return obj;
                }, {})}
                value={dduManager ? dduManager : ''}
                onChange={(event) => setDduManager(event.target.value)}
              />
            )}
            {status === '79' && (
              <Select
                moduleStyles={styles.status}
                label="Кем"
                options={managers.items.reduce((obj: IOption, item) => {
                  obj[item.id] = `${item.name} ${item.lastName}`;
                  return obj;
                }, {})}
                value={bookManager ? bookManager : ''}
                onChange={(event) => setBookManager(event.target.value)}
              />
            )}
            {status === '83' && (
              <Select
                moduleStyles={styles.status}
                label="Категория юр. услуг"
                options={legalEntityOptions}
                value={legalCategory ? legalCategory : ''}
                onChange={(event) =>
                  setLegalCategory(
                    event.target.value as EntityLegalCategoryType,
                  )
                }
              />
            )}
            <Input
              moduleStyles={styles.roomPrice}
              label="Площадь помещения"
              type="number"
              min="0"
              step="0.01"
              value={roomArea === 0 ? '' : roomArea}
              onChange={(event) => setRoomArea(+event.target.value)}
            />
            <Input
              moduleStyles={styles.roomPrice}
              label="Цена помещения"
              type="number"
              min="0"
              step="0.01"
              value={roomPrice === 0 ? '' : roomPrice}
              onChange={(event) => setRoomPrice(+event.target.value)}
            />
            <Textarea
              moduleStyles={styles.comment}
              label="Комментарий"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />

            {certificateMarkup}

            <Button label="Изменить" moduleStyles={styles.submit} />
          </form>
        </div>
      </div>
    </Modal>
  );
};
