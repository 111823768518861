import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './input.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  moduleStyles?: string;
}

export const Input: FC<Props> = ({ label, moduleStyles, ...props }) => {
  const moduleClasses = cn(styles.module, moduleStyles);

  return (
    <label className={moduleClasses}>
      <span className={styles.label}>{label}</span>
      <input className={styles.input} {...props} />
    </label>
  );
};
