import { FC } from 'react';
import { IQueue } from 'features/objects/types';
import styles from './village-queue-selector.module.scss';
import { Button } from 'components/button';

interface Props {
  queues: IQueue[];
}

export const VillageQueueSelector: FC<Props> = ({ queues }) => {
  return (
    <div className={styles.module}>
      <p className={styles.title}>Выбрать очередь</p>
      <div className={styles.buttons}>
        {queues.map((item) => (
          <Button
            moduleStyles={styles.button}
            key={item.id}
            label={item.name}
          />
        ))}
      </div>
    </div>
  );
};
