import axios from 'axios';
import { IEntity, IFlat, IObject } from 'features/objects/types';
import { domain } from './constants';
import {
  EditFlatSubmitData,
  EditEntitySubmitData,
  ErrorResponse,
} from './types';

const apiClient = axios.create({
  baseURL: `${domain}/api/objects`,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const objectsAPI = {
  getObjects: async () => {
    try {
      const { data } = await apiClient.post<IObject[] | ErrorResponse>(
        '/fetch.php'
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
  getObject: async (id: string) => {
    try {
      const { data } = await apiClient.post<IObject | ErrorResponse>(
        '/fetchOne.php',
        id
      );

      if ('errorType' in data) {
        const error = data;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
  editEntity: async (submitData: EditEntitySubmitData) => {
    try {
      const response = await apiClient.post<IEntity | ErrorResponse>(
        '/editEntity.php',
        submitData
      );

      if ('errorType' in response.data) {
        const error = response.data;
        throw error;
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  editFlat: async (submitData: EditFlatSubmitData) => {
    try {
      const response = await apiClient.post<IFlat | ErrorResponse>(
        '/editFlat.php',
        submitData
      );

      if ('errorType' in response.data) {
        const error = response.data;
        throw error;
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
